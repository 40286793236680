import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { Asset, AssetType } from '@remento/types/asset';
import { EntityType } from '@remento/types/entity';
import { PromptType } from '@remento/types/project';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMCloseButton } from '@/components/RMCloseButton/RMCloseButton';
import { RMConfirmationModal } from '@/components/RMConfirmationModal';
import { RMDialog } from '@/components/RMDialog';
import { RMIconButton } from '@/components/RMIconButton/RMIconButton';
import { RMText } from '@/components/RMText/RMText';
import { toast } from '@/components/RMToast/RMToast';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { isFormDirty, resetForm, submitForm, useIsFormValid } from '@/modules/form/form';
import { getInputValue } from '@/modules/form/input';
import { useServices } from '@/Services';
import { PromptTemplateTag } from '@/services/cms/prompt-template-tags/prompt-template-tags.types';
import { captureException } from '@/utils/captureException';
import { secureUuid } from '@/utils/uuid';

import { CreatePromptPayload } from '../../../services/api/project';
import { AddPromptsDialogFooter } from '../components/AddPromptsDialogFooter/AddPromptsDialogFooter';
import { AddPromptsHomeScreen } from '../components/AddPromptsHomeScreen/AddPromptsHomeScreen';
import { AddPromptsTagScreen } from '../components/AddPromptsTagScreen/AddPromptsTagScreen';
import { PromptInput } from '../components/PromptInput/PromptInput';
import { createPromptEditForm } from '../prompt-edit.form';
import {
  closePromptDialog,
  ProjectDialogPanelStore,
  useProjectDialogPanelState,
} from '../states/project-dialog-panel.state';
import {
  getPromptDraft,
  getPromptDrafts,
  isPromptDraftEdited,
  PromptDraftsStore,
  resetPromptDrafts,
  updatePromptDraftPhoto,
  updatePromptDraftQuestion,
  usePromptDraftsCount,
} from '../states/prompt-drafts.state';

import { PromptTemplateListContainer } from './PromptTemplateList.container';
import { PromptTemplateReviewListContainer } from './PromptTemplateReviewList.container';
import { PromptTemplateTagListContainer } from './PromptTemplateTagList.container';
import { UpdatePromptTemplateContainer } from './UpdatePromptTemplate.container';

interface AddPromptsSelectionState {
  type: 'selection';
}

interface AddPromptsTagSelectionState {
  type: 'tag-selection';
}

interface AddPromptsReviewState {
  type: 'review';
}

interface AddPromptsEditState {
  type: 'edit';
  promptId: string;
}

type AddPromptsState =
  | AddPromptsSelectionState
  | AddPromptsTagSelectionState
  | AddPromptsReviewState
  | AddPromptsEditState;

type AddPromptsDialogContainerProps = {
  promptDraftsStore: PromptDraftsStore;
  panelStore: ProjectDialogPanelStore;
  projectId: string;
  onPromptsAdded?: () => void;
  onBack?: () => void;
  onClose?: () => void;
};

export function AddTextPromptsContainer({
  promptDraftsStore,
  panelStore,
  projectId,
  onPromptsAdded,
  onBack,
  onClose,
}: AddPromptsDialogContainerProps) {
  const isMobile = useIsMobileViewport();
  const { assetService, projectCacheService, promptAnalyticsService, webappAnalyticsService } = useServices();
  const panelState = useProjectDialogPanelState(panelStore);

  const [state, setState] = useState<AddPromptsState>({ type: 'selection' });
  const [tag, setTag] = useState<PromptTemplateTag>();
  const [direction, setDirection] = useState<'ltr' | 'rtl'>('rtl');
  const promptCount = usePromptDraftsCount(promptDraftsStore);

  const initialForm = useMemo(createPromptEditForm, []);
  const form = useRef(initialForm);
  const isCurrentFormValid = useIsFormValid(form.current);

  const customPromptForm = useMemo(createPromptEditForm, []);

  // @TODO: Remove this when we have File support in the form module
  const [photo, setPhoto] = useState<{ url: string; handle: string } | null>(null);
  const photoRef = useRef(photo);
  const [unsavedPromptChangesModalOpen, setUnsavedPromptChangesModalOpen] = useState(false);

  const [unsavedPromptsModalOpen, setUnsavedPromptsModalOpen] = useState(false);

  useLayoutEffect(() => {
    photoRef.current = photo;
  }, [photo]);

  const handleClose = () => {
    if (promptCount > 0) {
      setUnsavedPromptsModalOpen(true);
      return;
    }

    closePromptDialog(panelStore);
    promptAnalyticsService.onPromptBankActionPerformed('close');
    onClose?.();
  };

  const getCurrentTitle = () => {
    switch (state.type) {
      case 'selection':
        return 'Ask a question';
      case 'tag-selection':
        return 'Select questions';
      case 'review':
        return 'Review your selections';
      case 'edit':
        return 'Edit prompt';
    }
  };

  const handleGoBack = () => {
    setDirection('ltr');
    setTimeout(() => {
      switch (state.type) {
        case 'selection':
          onBack?.();
          break;
        case 'tag-selection':
          setState({ type: 'selection' });
          break;
        case 'review':
          setState({ type: 'tag-selection' });
          break;
        case 'edit':
          if (isFormDirty(form.current) || photoRef.current !== null) {
            setUnsavedPromptChangesModalOpen(true);
            return;
          }

          setState({ type: 'review' });
          break;
      }
    });
    promptAnalyticsService.onPromptBankActionPerformed('back');
  };

  const handleGoToTagSelection = (tag: PromptTemplateTag) => {
    setDirection('rtl');
    setTimeout(() => {
      setTag(tag);
      setState({ type: 'tag-selection' });
    });
  };

  const handleGoToReview = () => {
    setDirection('rtl');
    setTimeout(() => setState({ type: 'review' }));
    promptAnalyticsService.onPromptBankActionPerformed('next');
  };

  const handleGoToEdit = (promptId: string) => {
    setDirection('rtl');
    setTimeout(() => setState({ type: 'edit', promptId }));
  };

  const resetPromptEditState = () => {
    form.current = createPromptEditForm();
    setPhoto(null);
  };

  const handleDiscardPromptChanges = () => {
    resetPromptEditState();
    setUnsavedPromptChangesModalOpen(false);
    handleGoBack();
  };

  const handleSavePromptChanges = async () => {
    if (state.type !== 'edit') {
      return;
    }

    const prompt = getPromptDraft(promptDraftsStore, state.promptId);
    if (!prompt) {
      return;
    }

    const newQuestionText = getInputValue(form.current, 'question');
    if (!newQuestionText) {
      return;
    }
    if (newQuestionText !== prompt.question) {
      updatePromptDraftQuestion(promptDraftsStore, prompt.id, newQuestionText);
    }
    if (photo !== null) {
      updatePromptDraftPhoto(promptDraftsStore, prompt.id, photo);
    }

    resetPromptEditState();
    setUnsavedPromptChangesModalOpen(false);
    handleGoBack();

    // Analytics
    promptAnalyticsService.onPromptBankPromptEdited({
      templateId: prompt.type === PromptType.TEXT ? prompt.template?.id ?? '' : '',
      templateLabel: prompt.question,
    });
  };

  const closeAndResetDialog = () => {
    closePromptDialog(panelStore);

    // Wait until the dialog is closed, so it doesn't feel janky
    setTimeout(() => {
      resetPromptEditState();
      resetPromptDrafts(promptDraftsStore);
      setState({ type: 'selection' });
      onClose?.();
    });
  };

  const handleDiscardPrompts = () => {
    setUnsavedPromptsModalOpen(false);
    closeAndResetDialog();
    promptAnalyticsService.onPromptBankActionPerformed('discard-yes');
  };

  const handleSavePrompts = async () => {
    const selectedPrompts = getPromptDrafts(promptDraftsStore);
    const prompts: CreatePromptPayload[] = await Promise.all(
      selectedPrompts.map(async (prompt) => {
        let asset: Asset | null = null;
        if (prompt.type === PromptType.PHOTO) {
          asset = await assetService.createFilestackAsset({
            handle: prompt.photo.handle,
            type: AssetType.IMAGE,
            entity: {
              type: EntityType.PROMPT,
              id: prompt.id,
            },
          });
          return {
            id: prompt.id,
            type: PromptType.PHOTO,
            status: null,
            imagesIds: [asset.id],
            question: prompt.question,
          };
        }
        const isEdited = isPromptDraftEdited(promptDraftsStore, prompt.id);
        return {
          id: prompt.id,
          type: PromptType.TEXT,
          status: null,
          question: prompt.question,
          template: isEdited ? null : prompt.template,
        };
      }),
    );

    await projectCacheService.createPrompts(projectId, prompts);

    // Analytics
    promptAnalyticsService.onPromptBankActionPerformed('save');

    for (const prompt of selectedPrompts) {
      promptAnalyticsService.onPromptBankPromptAdded({
        templateId: prompt.type === PromptType.TEXT ? prompt.template?.id ?? '' : '',
        templateLabel: prompt.question,
        trending: false,
        customization: isPromptDraftEdited(promptDraftsStore, prompt.id),
      });
    }

    promptAnalyticsService.onPromptBankPromptsAdded(prompts.length);

    // Close modal
    closeAndResetDialog();
    onPromptsAdded?.();
    toast('Prompt(s) added');
  };

  const handleSaveCustomPrompt = async () =>
    submitForm(
      customPromptForm,
      async (formValues) => {
        const prompt: CreatePromptPayload = {
          type: PromptType.TEXT,
          id: secureUuid(),
          status: null,
          question: formValues.question,
          template: null,
        };

        await projectCacheService.createPrompts(projectId, [prompt]);

        // Analytics
        promptAnalyticsService.onPromptBankCustomPromptAdded();
        promptAnalyticsService.onPromptBankActionPerformed('save');
        promptAnalyticsService.onPromptBankPromptsAdded(1);

        resetForm(customPromptForm);
        onPromptsAdded?.();
      },
      (err) => {
        captureException(err);
      },
    );

  // Analytics
  useEffect(() => {
    if (panelState.type === 'add-prompt') {
      promptAnalyticsService.onPromptBankArrived();
      webappAnalyticsService.onPageArrived('prompt-bank');
    }
  }, [panelState.type, promptAnalyticsService, webappAnalyticsService]);

  useEffect(() => {
    if (state.type === 'review') {
      webappAnalyticsService.onPageArrived('prompt-bank-review');
    }
  }, [state.type, webappAnalyticsService]);

  return (
    <>
      <RMDialog.Header
        centerTitle={true}
        title={getCurrentTitle()}
        leftAdornment={
          <RMIconButton
            icon={faChevronLeft}
            tooltip={null}
            backgroundColor="transparent"
            stateColor="darken-neutral"
            onClick={handleGoBack}
          />
        }
        rightAdornment={<RMCloseButton onClick={handleClose} />}
      ></RMDialog.Header>

      <RMDialog.Body>
        <RMDialog.Transition key={state.type} animation={direction}>
          {state.type === 'selection' && (
            <AddPromptsHomeScreen
              PromptTemplateInput={
                <PromptInput onButtonClick={handleSaveCustomPrompt} form={customPromptForm}></PromptInput>
              }
              PromptTemplateTagList={<PromptTemplateTagListContainer onSelectTag={handleGoToTagSelection} />}
            />
          )}
          {state.type === 'tag-selection' && tag != null && (
            <AddPromptsTagScreen
              tag={tag}
              PromptTemplateList={<PromptTemplateListContainer promptDraftsStore={promptDraftsStore} tag={tag} />}
            />
          )}
          {state.type === 'review' && (
            <PromptTemplateReviewListContainer promptDraftsStore={promptDraftsStore} onEditPrompt={handleGoToEdit} />
          )}
          {state.type === 'edit' && (
            <UpdatePromptTemplateContainer
              promptId={state.promptId}
              promptDraftsStore={promptDraftsStore}
              form={form.current}
              photo={photo?.url ?? null}
              onPhotoChange={setPhoto}
            />
          )}
        </RMDialog.Transition>
      </RMDialog.Body>

      {promptCount > 0 && (
        <RMDialog.Footer>
          {(state.type === 'selection' || state.type === 'tag-selection') && (
            <AddPromptsDialogFooter>
              <RMText type="sans" size={isMobile ? 'xs' : 's'} bold>
                {promptCount} {promptCount === 1 ? 'question' : 'questions'} selected
              </RMText>

              <RMButton background="primary" onClick={handleGoToReview} fullWidth>
                Next
              </RMButton>
            </AddPromptsDialogFooter>
          )}

          {state.type === 'review' && (
            <RMButton background="primary" onClick={handleSavePrompts} fullWidth autoLoading>
              Add {promptCount} {promptCount === 1 ? 'Question' : 'Questions'} To Queue
            </RMButton>
          )}

          {state.type === 'edit' && (
            <RMButton background="primary" disabled={!isCurrentFormValid} onClick={handleSavePromptChanges} fullWidth>
              Save
            </RMButton>
          )}
        </RMDialog.Footer>
      )}

      <RMConfirmationModal
        open={unsavedPromptsModalOpen}
        title="Discard questions?"
        message="Your selected questions will not be saved if you proceed."
        confirmLabel="Discard questions"
        type="danger"
        onConfirm={handleDiscardPrompts}
        onCancel={() => {
          promptAnalyticsService.onPromptBankActionPerformed('discord-no');
          setUnsavedPromptsModalOpen(false);
        }}
      />

      <RMConfirmationModal
        open={unsavedPromptChangesModalOpen}
        title="Save changes?"
        message="You have unsaved changes on this page. Would you like to save them?"
        confirmLabel="Save changes"
        cancelLabel="Discard"
        onConfirm={handleSavePromptChanges}
        onCancel={handleDiscardPromptChanges}
        onClose={() => setUnsavedPromptChangesModalOpen(false)}
      />
    </>
  );
}
