import { useCallback, useEffect, useRef, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import lottie, { AnimationItem } from 'lottie-web';

import CheckmarkLottieAnimation from '@/@assets/checkmark-lottie-animation.json';
import { RMButton } from '@/components/RMButton/RMButton';
import { RMInput } from '@/components/RMInput/RMInput';
import { RMInputCharCounter } from '@/components/RMInput/RMInputCharCounter';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { InputContainer } from '@/modules/form/container';
import { useIsFormValid } from '@/modules/form/form';
import { useInputValue } from '@/modules/form/input';
import { useNavbarProjectId, useNavbarStore } from '@/modules/navbar/states';
import { usePersonQuery } from '@/services/api/person';
import { useProjectQuery } from '@/services/api/project';

import { PromptEditForm } from '../../prompt-edit.form';

import {
  ButtonWrapper,
  InputWrapper,
  LottieWrapper,
  SuccessMessageLabel,
  SuccessMessageWrapper,
} from './PromptInput.styles';

export interface PromptInputProps {
  form: PromptEditForm;
  onButtonClick?: () => void;
}

export function PromptInput({ form, onButtonClick }: PromptInputProps) {
  const formValid = useIsFormValid(form);
  const question = useInputValue(form, 'question');
  const mobile = useIsMobileViewport();
  const navbarStore = useNavbarStore();
  const projectId = useNavbarProjectId(navbarStore);
  const projectQuery = useProjectQuery(projectId);
  const project = projectQuery.data;
  const personQuery = usePersonQuery(project?.notifications.recipientPersonIds[0]);
  const firstName = personQuery.data?.name?.first;

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  useEffect(() => {
    if (!showSuccessMessage) {
      return;
    }

    const timeout = setTimeout(() => setShowSuccessMessage(false), 2000);

    return () => {
      if (timeout != null) clearTimeout(timeout);
    };
  }, [showSuccessMessage]);

  // Lottie animation
  const animationRef = useRef<AnimationItem | null>(null);
  const lottieRef = useCallback((node: HTMLDivElement | null) => {
    if (node === null) {
      if (animationRef.current) {
        animationRef.current.destroy();
        animationRef.current = null;
      }
      return;
    }

    const animation = lottie.loadAnimation({
      container: node,
      renderer: 'svg',
      loop: false,
      autoplay: true,
      animationData: CheckmarkLottieAnimation,
    });
    animationRef.current = animation;
  }, []);

  return (
    <InputWrapper data-mobile={mobile} showSuccess={showSuccessMessage}>
      <InputContainer form={form} path="question">
        {(props) => (
          <RMInput
            id="text-prompt-dialog-custom-field"
            validation={false}
            placeholder={`Ask ${firstName} a question...`}
            textarea
            fontFamily="sans"
            {...props}
          />
        )}
      </InputContainer>

      <RMInputCharCounter length={question?.length ?? 0} maxLength={120} />
      {onButtonClick && (
        <ButtonWrapper>
          <RMButton
            background="primary"
            autoLoading
            disabled={!formValid}
            onClick={async () => {
              await onButtonClick?.();
              setShowSuccessMessage(true);
            }}
          >
            Add question
          </RMButton>
        </ButtonWrapper>
      )}

      <AnimatePresence>
        {showSuccessMessage && (
          <SuccessMessageWrapper
            data-mobile={mobile}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <LottieWrapper ref={lottieRef} />
            <SuccessMessageLabel>Question added to queue!</SuccessMessageLabel>
          </SuccessMessageWrapper>
        )}
      </AnimatePresence>
    </InputWrapper>
  );
}
